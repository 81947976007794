.wrap {
  font-size: 14px;
  font-size: var(--fs-s);
  width: 100%;
  padding: 0.5rem;
  padding: var(--s-1);
}

.single {
  margin-bottom: 1.25rem;
  margin-bottom: var(--s-3);
  position: relative;
  margin-right: -2px;
}

.single:after {
  content: "";
  position: absolute;
  top: 100%;
  margin-top: calc(1.25rem / 2);
  margin-top: calc(var(--s-3) / 2);
  left: calc(1rem * -1);
  left: calc(var(--s-2) * -1);
  right: calc(1rem * -1);
  right: calc(var(--s-2) * -1);
  border-bottom: 2px solid #fff;
  border-bottom: 2px solid var(--c-light);
}

.single:last-child {
  margin-bottom: 0;
}

.single:last-child:after {
  display: none;
}

.num {
  font-size: 32px;
  font-size: var(--fs-xxl);
  color: #346f6f;
  color: var(--c-bright-green);
  display: inline-block;
  line-height: 1;
  -webkit-transform: translateY(5%);
          transform: translateY(5%);
  opacity: 0;
  transition: 0.4s ease-out;
  font-family: "Noto Serif", "Serif";
  font-family: var(--ff-headers);
  font-weight: 600;
  text-align: center;
  margin-right: 1rem;
  margin-right: var(--s-2);
}

.isSecond .num {
  transition-delay: 0.2s;
}

.isVisible .num {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  opacity: 1;
}

.single {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
  margin-bottom: var(--s-3);
}

.text {
  flex: 1;
}

.dots {
  flex: 1;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}

.dot {
  width: 12px;
  height: 12px;
  background: #346f6f;
  background: var(--c-bright-green);
  border-radius: 50%;
  -webkit-transform: translateY(5px);
          transform: translateY(5px);
  opacity: 0;
  transition: 0.5s ease-out;
}

.isVisible .dot {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
