/* autoprefixer grid: on */

.wrap {
}

.title {
  text-transform: uppercase;
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  font-size: var(--fs-s);
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-family: var(--ff-body);
}

.grid {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(1rem * -1);
  margin-top: calc(var(--s-2) * -1);
  margin-left: calc(1rem * -1);
  margin-left: calc(var(--s-2) * -1);
}

.gridItem {
  width: 100%;
  /* grid-column: span 1; */
  display: flex;
  padding-top: 1rem;
  padding-top: var(--s-2);
  padding-left: 1rem;
  padding-left: var(--s-2);
}

@media (min-width: 600px) {
  .gridItem {
    flex: 1 33.3%;
  }
}
