.wrap {
  font-size: 12px;
  font-size: var(--fs-xs);
}

.title {
  font-size: 16px;
  font-size: var(--fs-m);
}

.datum {
  align-items: center;
  margin-bottom: 0.5rem;
  margin-bottom: var(--s-1);

  display: flex;
}

.datum:first-child {
  margin-bottom: 0;
}

.text {
  width: 85px;
  margin: 0 calc(0.5rem / 2);
  margin: 0 calc(var(--s-1) / 2);
  text-align: center;
}

.smallText {
  width: 0;
}

@media (min-width: 580px) {
  .smallText {
    width: 100px;
  }
}

@media (min-width: 760px) {
  .text {
    width: 100px;
  }
}

.bars {
  position: relative;
  background: #fff;
  background: var(--c-light);
  border-radius: 4rem;
  flex: 1;
}

.bars:first-child {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.barWrap:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.bar {
  position: relative;
  transition: 1.5s cubic-bezier(0.1, 0.75, 0.19, 1);
  height: 1.4rem;
  opacity: 1;
  border-radius: 4rem;
  background: #346f6f;
  background: var(--c-bright-green);
}

.barReversed {
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.barWrap:nth-child(2) .bar {
  background: transparent;
}

.bar:after {
  content: "";
  position: absolute;
  right: 0;
  width: 4px;
  height: 4px;
  top: 50%;
  border-radius: 50%;
  background: #000;
  background: var(--c-dark);
  border: 1px solid;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.barWrap:nth-child(2) .bar:before {
  content: "";
  position: absolute;
  left: 2px;
  right: 2px;
  height: 1px;
  top: 50%;
  background: #000;
  background: var(--c-dark);
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
}

.barWrap:nth-child(2) .bar:after {
  left: 0;
  right: auto;
  background: transparent;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.wrap:not(.isVisible) .bar {
  width: 0 !important;
  opacity: 0;
}

.key {
  text-align: center;
  flex: 1;
}

.ticks {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-size: var(--fs-xs);
  opacity: 0.5;
}

.example {
  display: inline-block;
  width: 20px;
  height: 1px;
  background: currentColor;
  vertical-align: middle;
  position: relative;
  margin-left: 6px;
  margin-right: 6px;
}

.exampleReversed {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.example:after,
.example:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  border-radius: 50%;
  border: 1px solid;
  height: 4px;
  width: 4px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.example:before {
  left: auto;
  right: 100%;
  background: currentColor;
}
