.bg {
}

.wrap {
  position: relative;
  text-align: center;
  position: relative;
  z-index: 1;
}

.question,
.prompt {
  max-width: 28.8rem;
  max-width: var(--w-s);
  margin-left: auto;
  margin-right: auto;
}

.question {
  font-family: "Noto Serif", "Serif";
  font-family: var(--ff-headers);
  font-weight: 600;
  font-size: 20px;
  font-size: var(--fs-l);
  line-height: 1.4;
}

@media (min-width: 760px) {
  .question {
    line-height: 1.6;
  }
}

.button,
.slider,
.answer {
  margin-bottom: 2.5rem;
  margin-bottom: var(--s-4);
}

.prompt {
  opacity: 0.7;
  margin-bottom: 4rem;
  margin-bottom: var(--s-5);
}

.countryDetail {
}

.answer {
  display: inline-block;
  max-width: 28.8rem;
  max-width: var(--w-s);
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  font-size: var(--fs-xs);
  padding: 1rem 1.25rem;
  padding: var(--s-2) var(--s-3);
  border-radius: 2.5rem;
  border-radius: var(--s-4);
  background: rgba(253,238,224, 0.5);
  background: var(--c-transparent-yellow);
}
