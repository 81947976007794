@-webkit-keyframes fader {
  0% {
    -webkit-transform: translateX(-100%) scale(0.9);
            transform: translateX(-100%) scale(0.9);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(0%) scale(1);
            transform: translateX(0%) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%) scale(0.9);
            transform: translateX(100%) scale(0.9);
    opacity: 0;
  }
}

@keyframes fader {
  0% {
    -webkit-transform: translateX(-100%) scale(0.9);
            transform: translateX(-100%) scale(0.9);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(0%) scale(1);
            transform: translateX(0%) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%) scale(0.9);
            transform: translateX(100%) scale(0.9);
    opacity: 0;
  }
}

.wrap {
  display: inline-block;
  transition: 0.2s ease-out;
  opacity: 0;
  position: relative;
}

.isVisible {
  opacity: 1;
}

.arrow {
  display: inline-block;
  -webkit-animation: fader 3s infinite ease-out;
          animation: fader 3s infinite ease-out;
}

.arrow:nth-child(1),
.arrow:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  position: absolute;
}

.arrow:nth-child(2) {
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
}
