.wrap {
  padding-bottom: 100%;
  height: 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;

  margin-left: calc(-2.5% - 1.25rem);

  margin-left: calc(-2.5% - var(--s-3));
  margin-right: calc(-2.5% - 1.25rem);
  margin-right: calc(-2.5% - var(--s-3));
  pointer-events: none;
}

@media (min-width: 500px) {
  .wrap {
    margin-left: calc(-16.6% - 1.25rem);
    margin-left: calc(-16.6% - var(--s-3));
    margin-right: calc(-16.6% - 1.25rem);
    margin-right: calc(-16.6% - var(--s-3));
  }
}

.canvas {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: -1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
