.wrap {
  font-size: 12px;
  font-size: var(--fs-xs);
}

.title {
  font-size: 14px;
  font-size: var(--fs-s);
}

.barWrap {
  border-radius: 4rem;
  background: #fff;
  background: var(--c-light);
  overflow: hidden;
  margin-bottom: 1rem;
  margin-bottom: var(--s-2);
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

.barWrap:last-child {
  margin-bottom: 0;
}

.bar {
  position: relative;
  padding: 0.1rem 0.5rem;
  padding: 0.1rem var(--s-1);
  transition: 1.5s cubic-bezier(0.1, 0.75, 0.19, 1);
  color: #5f5f5f;
  color: var(--c-grey);
}

.wrap:not(.isVisible) .bar {
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important;
}

.value {
  opacity: 0;
  transition: 0.3s ease-out;
}

.isVisible .value {
  opacity: 1;
}

.bar:before {
  content: "";
  position: absolute;
  right: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  border-radius: 4rem;
  background: #346f6f;
  background: var(--c-bright-green);
}
