.wrap {
}

.title {
  font-size: 16px;
  font-size: var(--fs-m);
}

.map {
  position: relative;
  height: 400px;
  margin-left: calc(-1px + 1rem * -1);
  margin-left: calc(-1px + var(--s-2) * -1);
  margin-right: calc(-1px + 1rem * -1);
  margin-right: calc(-1px + var(--s-2) * -1);
  margin-top: calc(-1px + 0.5rem * -1);
  margin-top: calc(-1px + var(--s-1) * -1);
  margin-bottom: calc(-1px + 1rem * -1);
  margin-bottom: calc(-1px + var(--s-2) * -1);
}

.node {
  position: absolute;
  z-index: 1;
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  opacity: 0.35;
  transition: 0.4s ease-out;
}

.isVisible .node {
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  opacity: 1;
}

.alignBottom {
  display: flex;
  align-items: flex-end;
}

.node::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background: #346f6f;
  background: var(--c-bright-green);
  opacity: 0.5;
  z-index: -1;
}

.text {
  display: inline-block;
  font-size: 12px;
  font-size: var(--fs-xs);
  line-height: 1.2;
  max-width: 100px;
  -webkit-transform: translateY(5px);
          transform: translateY(5px);
  opacity: 0;
  transition: 0.3s ease-out;
  margin: 1rem;
  margin: var(--s-2);
}

.isVisible .text {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.value {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-size: var(--fs-m);
  position: relative;
  font-family: "Noto Serif", "Serif";
  font-family: var(--ff-headers);
  font-weight: 600;
}

.hasExternalLabel .text {
  -webkit-transform: translate(-95%);
          transform: translate(-95%);
  display: block;
  width: 100%;
  min-width: 70px;
  max-width: auto;
  overflow: hidden;
}

.hasExternalLabel .value:after {
  content: "";
  display: block;
  height: 1px;
  flex: 1;
  margin-left: 0.5rem;
  margin-left: var(--s-1);
  background: currentColor;
}

.source {
  font-size: 12px;
  font-size: var(--fs-xs);
}

.isSouthAsia .text {
  -webkit-transform: translate(-111%, -20%);
          transform: translate(-111%, -20%);
}
