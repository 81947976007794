/* autoprefixer grid: on */

.wrap {
  opacity: 0.2;
  transition: 0.2s ease-out;
}

.isVisible {
  opacity: 1;
}

.title {
  text-transform: uppercase;
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  font-size: var(--fs-s);
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-family: var(--ff-body);
}

.source {
  margin-top: 1rem;
  margin-top: var(--s-2);
  font-size: 12px;
  font-size: var(--fs-xs);
  opacity: 0.7;
  text-align: left;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(1rem * -1);
  margin-left: calc(var(--s-2) * -1);
  text-align: left;
  justify-content: center;
}

.gridItem {
  width: calc(50% - 1rem * 2);
  width: calc(50% - var(--s-2) * 2);
  margin: 1rem;
  margin: var(--s-2);
  background: #dcf1e4;
  background: var(--c-green);
  padding: 1rem;
  padding: var(--s-2);
  display: flex;
  justify-content: center;
  align-items: center;
}

@supports (display: grid) {
  .grid {
    display: grid;
    grid-gap: 1rem;
    grid-gap: var(--s-2);
    margin-left: 0;
  }

  .gridItem {
    width: auto;
    margin: 0;
    grid-column: span 1;
  }

  @media (min-width: 600px) {
    .just-the-job {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }

    .start-to-save {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }

    .start-to-save .gridItem:first-child {
      grid-row: span 2;
    }

    .start-to-save .gridItem:last-child {
      grid-column: 2 / 4;
    }

    .against-your-will,
    .moving-on-up {
      grid-template-columns: repeat(3, 1fr);
    }

    .against-your-will .gridItem:not(:last-child),
    .moving-on-up .gridItem:last-child {
      background: rgb(251, 237, 225);
    }

    .going-places,
    .retirement,
    .loaded-questions,
    .loan-survivor {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }

    .going-places .gridItem:first-child,
    .retirement .gridItem:first-child,
    .loaded-questions .gridItem:first-child,
    .loan-survivor .gridItem:first-child {
      grid-column: span 2;
      grid-row: span 2;
    }

    .problem-child {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }

    .problem-child .gridItem:first-child {
      grid-column: span 3;
    }
  }
}
