.button {
  padding: 1rem 1.25rem;
  padding: var(--s-2) var(--s-3);
  font: inherit;
  border: 0;
  cursor: pointer;
  text-align: center;
  border-radius: 1.25rem;
  border-radius: var(--s-3);
  font-size: 12px;
  font-size: var(--fs-xs);
  letter-spacing: 0.6px;
  line-height: 1;
  background: #f97031;
  background: var(--c-orange);
  box-shadow: 0 2px 9px 0 rgba(249,112,49, 0.58);
  box-shadow: 0 2px 9px 0 var(--c-transparent-orange);
  text-transform: uppercase;
  font-weight: bold;
  min-width: 10rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-out;
  text-decoration: none;
}

.button:focus {
  outline-offset: 0.5rem;
  outline: 4px auto #9c9c9c;
  outline: 4px auto var(--c-light-grey);
}

.button:hover {
  background: #ffa780;
  background: var(--c-light-orange);
  box-shadow: 0 2px 9px 0 transparent;
}
