:root {
  /*  Colors  */
  --c-green: #dcf1e4;
  --c-faded-green: #b0e3d2;
  --c-bright-green: #346f6f;
  --c-dark: #000;
  --c-light: #fff;
  --c-orange: #f97031;
  --c-light-orange: #ffa780;
  --c-yellow: #fdeee0;

  --c-grey: #5f5f5f;
  --c-light-grey: #9c9c9c;

  --c-transparent-yellow: rgba(253,238,224, 0.5);
  --c-transparent-light: rgba(254,254,254, 0.72);
  --c-transparent-orange: rgba(249,112,49, 0.58);
  --c-transparent-dark: rgba(0,140,140, 0.21);

  /* Fonts  */
  --ff-body: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --ff-headers: "Noto Serif", "Serif";

  /* Font sizes */
  --fs-xs: 12px;
  --fs-s: 14px;
  --fs-xm: 16px;
  --fs-m: 16px;
  --fs-l: 20px;
  --fs-xl: 24px;
  --fs-xxl: 32px;

  /* Spacing  */
  --s-1: 0.5rem;
  --s-2: 1rem;
  --s-3: 1.25rem;
  --s-4: 2.5rem;
  --s-5: 4rem;

  /* Widths  */
  --w-s: 28.8rem;
  --w-m: 44rem;
  --w-l: 64rem;
}

@media (min-width: 760px) {
  :root {
    /* Font sizes */
    --fs-xs: 14px;
    --fs-s: 16px;
    --fs-xm: 18px;
    --fs-m: 20px;
    --fs-l: 24px;
    --fs-xl: 40px;
    --fs-xxl: 50px;

    /* Spacing  */
    --s-1: 0.5rem;
    --s-2: 1rem;
    --s-3: 2rem;
    --s-4: 4rem;
    --s-5: 8rem;
  }
}
