.wrap {
  transition: 0.4s ease-out;
  font-size: calc(16px * 0.9);
  font-size: calc(var(--fs-m) * 0.9);
  font-family: "Noto Serif", "Serif";
  font-family: var(--ff-headers);
  font-weight: 600;
  line-height: 1.5;
}

.isCelebrating {
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-family: var(--ff-body);
  font-size: 14px;
  font-size: var(--fs-s);
  font-weight: normal;
}

.icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  max-width: 200px;
  margin-bottom: 0.5rem;
  margin-bottom: var(--s-1);
}
