/* autoprefixer grid: on */

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

/* Border Box all the things */
* {
  box-sizing: border-box;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

html {
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-family: var(--ff-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;

  -webkit-font-feature-settings: "liga" 1, "kern" 1;

          font-feature-settings: "liga" 1, "kern" 1;
  word-wrap: break-word;
  word-wrap: break-word;
  color: #000;
  color: var(--c-dark);

  font-size: 14px;

  font-size: var(--fs-s);
  line-height: 1.4;
}

body {
  background: #dcf1e4;
  background: var(--c-green);
}

#root {
  padding: 1.25rem;
  padding: var(--s-3);
  overflow: hidden;
}

* {
  -webkit-tap-highlight-color: #000;
  -webkit-tap-highlight-color: var(--c-dark);
}

/* Shared vertical rhythm - csswizardry.com/2012/06/single-direction-margin-declarations */
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
ul,
ol,
ol > li,
dl,
blockquote,
p,
address,
table,
fieldset,
figure,
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  margin-bottom: var(--s-2);
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
hgroup:last-child,
ul:last-child,
ol:last-child,
ol > li:last-child,
dl:last-child,
blockquote:last-child,
p:last-child,
address:last-child,
table:last-child,
fieldset:last-child,
figure:last-child,
pre:last-child {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Serif", "Serif";
  font-family: var(--ff-headers);
  font-weight: 600;
  line-height: 1.3;
}

h1 {
  font-size: 32px;
  font-size: var(--fs-xxl);
}

h2 {
  font-size: 20px;
  font-size: var(--fs-l);
}

h3 {
  font-size: 16px;
  font-size: var(--fs-m);
}

h4 {
  font-size: 16px;
  font-size: var(--fs-m);
}

/* Makes images responsive by default */
img,
svg {
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
}

/* Remove the border around images in IE */
a img {
  border: 0;
}

figure {
  padding: 0;
  margin: 0;
}

/* Prevent superscripts and subscripts from affecting line-height */
sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

sub {
  top: 0.4em;
}

/* Form elements inherit fonts */
input,
textarea,
button {
  font: inherit;
}

a,
a:hover,
a:visited,
a:active {
  color: inherit;
}

li {
  margin-bottom: 0.5rem;
}

li:last-child {
  margin-bottom: 0;
}

.source-link {
  transition: 0.2s ease-out;
}

.source-link:hover {
  opacity: 0.75;
}
