.wrap {
  font-size: 16px;
  font-size: var(--fs-xm);
  text-decoration: none;
  font-family: "Noto Serif", "Serif";
  font-family: var(--ff-headers);
  font-weight: 600;
  display: flex;
  align-items: stretch;
  width: 100%;
  background: #fdeee0;
  background: var(--c-yellow);
  transition: 0.2s ease-out;
  padding: 1rem;
  padding: var(--s-2);
}

.wrap:hover {
  background: rgba(253,238,224, 0.5);
  background: var(--c-transparent-yellow);
}

.sub {
  font-size: 12px;
  font-size: var(--fs-xs);
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-family: var(--ff-body);
  color: #5f5f5f;
  color: var(--c-grey);
  font-weight: normal;
}

.text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon {
  margin-right: 1rem;
  margin-right: var(--s-2);
  width: 28%;
}

.title {
  position: relative;
}

.arrow {
  display: inline-block;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  vertical-align: middle;
  margin-left: 0.5rem;
  margin-left: var(--s-1);
  color: #f97031;
  color: var(--c-orange);
}
