.wrap {
  font-size: 16px;
  font-size: var(--fs-xm);
  text-decoration: none;
  font-family: "Noto Serif", "Serif";
  font-family: var(--ff-headers);
  font-weight: 600;
  display: flex;
  align-items: center;
  background: rgb(251, 237, 225);
  transition: 0.2s ease-out;
  padding: 1rem;
  padding: var(--s-2);
  margin: calc(1rem * -1);
  margin: calc(var(--s-2) * -1);
  width: calc(100% + 1rem * 2);
  width: calc(100% + var(--s-2) * 2);
}

.wrap:hover {
  background: rgb(253, 246, 240);
}

.sub {
  font-size: 12px;
  font-size: var(--fs-xs);
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-family: var(--ff-body);
  color: #5f5f5f;
  color: var(--c-grey);
}

.text {
  flex: 1;
}

.icon {
  margin-right: 1rem;
  margin-right: var(--s-2);
  width: 28%;
}

.title {
  position: relative;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  color: #f97031;
  color: var(--c-orange);
}

.arrowRight {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-left: 0.5rem;
  margin-left: var(--s-1);
}
