.wrap {
  margin: 0 auto;
  padding-top: 8rem;
  padding-bottom: 4rem;
  padding-bottom: var(--s-5);
}

.wrap:last-child {
  padding-bottom: 4rem;
  padding-bottom: var(--s-5);
}

.test {
  padding: 0 1.25rem 1.25rem;
  padding: 0 var(--s-3) var(--s-3);
  max-width: 64rem;
  max-width: var(--w-l);
  text-align: center;
  background: #fff;
  background: var(--c-light);
  border-radius: 3px;
  box-shadow: 0 0 23px 0 rgba(0,140,140, 0.21);
  box-shadow: 0 0 23px 0 var(--c-transparent-dark);
  margin-left: auto;
  margin-right: auto;
}

.inner {
  max-width: 44rem;
  max-width: var(--w-m);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
  margin-bottom: var(--s-4);
  padding-top: 8rem;
  position: relative;
}

.image {
  border-radius: 50%;
  width: 16rem;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.title {
  font-size: 24px;
  font-size: var(--fs-xl);
  margin-bottom: 1.25rem;
  margin-bottom: var(--s-3);
}

.copy {
  font-size: 16px;
  font-size: var(--fs-m);
  position: relative;
  padding-bottom: 1.25rem;
  padding-bottom: var(--s-3);
}

.copy:after {
  content: "";
  position: absolute;
  bottom: calc(1.25rem * -1);
  bottom: calc(var(--s-3) * -1);
  left: 50%;
  height: 1.25rem;
  height: var(--s-3);
  width: 1px;
  background: currentColor;
  /* transform: translateX(-50%); */
}

.copy:before {
  content: "";
  position: absolute;
  bottom: calc(1.25rem * -1);
  bottom: calc(var(--s-3) * -1);
  left: 50%;
  height: 6px;
  width: 6px;
  background: transparent;
  border-bottom: 1px solid;
  border-right: 1px solid;
  -webkit-transform: translateX(-3px) rotate(45deg);
          transform: translateX(-3px) rotate(45deg);
}

.source {
  opacity: 0.7;
  margin-top: 1.25rem;
  margin-top: var(--s-3);
  font-size: 12px;
  font-size: var(--fs-xs);
  text-align: center;
}

.cta {
  padding-top: 1.25rem;
  padding-top: var(--s-3);
  padding-bottom: 0.5rem;
  padding-bottom: var(--s-1);
  color: #346f6f;
  color: var(--c-bright-green);
  max-width: 28.8rem;
  max-width: var(--w-s);
  margin-left: auto;
  margin-right: auto;
}

.cta p:first-child {
  display: inline-block;
  font-family: "Noto Serif", "Serif";
  font-family: var(--ff-headers);
  font-size: 32px;
  font-size: var(--fs-xxl);
  font-weight: bold;
  letter-spacing: -1.15px;
  margin-bottom: var(--s-s);
  position: relative;
}

.confetti {
  position: absolute;
  top: -12px;
  left: 100%;
  width: 48px;
  height: 48px;
  -webkit-transform: scaleX(0.5) rotate(120deg) translate(0, 10%);
          transform: scaleX(0.5) rotate(120deg) translate(0, 10%);
  /* opacity: 0; */
  transition: 0.3s 0.2s cubic-bezier(0.61, 0.48, 0.82, 1.8);
  /* transition: 0.2s 0.4s ease-out; */
}

.confetti svg {
  vertical-align: top;
}

.confetti:first-child {
  left: auto;
  right: 100%;
  -webkit-transform: scaleX(0.5) rotate(0deg) translate(0%, 10%);
          transform: scaleX(0.5) rotate(0deg) translate(0%, 10%);
}

.isVisible .confetti {
  -webkit-transform: scale(1) rotateY(180deg) translateX(0%);
          transform: scale(1) rotateY(180deg) translateX(0%);
  opacity: 1;
}

.isVisible .confetti:first-child {
  -webkit-transform: scale(1) rotate(0deg) translate(0%, 10%);
          transform: scale(1) rotate(0deg) translate(0%, 10%);
}

.share {
  margin-bottom: 2.5rem;
  margin-bottom: var(--s-4);
}

.share p:first-child {
  font-size: 16px;
  font-size: var(--fs-m);
}

.shareButton {
  display: inline-block;
  padding: 0.5rem;
  padding: var(--s-1);
}

.shareButton svg {
  width: 14px;
  width: var(--fs-s);
  height: 14px;
  height: var(--fs-s);
  margin-left: 0.5rem;
  margin-left: var(--s-1);
}
