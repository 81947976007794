.wrap {
  position: relative;
  padding-bottom: 1rem;
  padding-bottom: var(--s-2);
}

.controls {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: visible;
}

@media (min-width: 500px) {
  .controls {
    width: 75%;
  }
}

.guess {
  display: block;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 10px;
  margin: 0 0.75rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  pointer-events: none;
}

.outputWrap {
  display: block;
  transition: 0.1s ease-out;
  position: relative;
  pointer-events: none;
}

.outputWrapSlow {
  transition-duration: 0.11s;
  z-index: 9;
}

.guessText {
  position: absolute;
  bottom: 0;
  left: 0%;
  -webkit-transform: translateX(-50%) translateY(5px);
          transform: translateX(-50%) translateY(5px);
  border-radius: 50%;
  padding: 1rem;
  width: 3rem;
  height: 3rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guessText:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f97031;
  background: var(--c-orange);
  border-radius: 50%;
  z-index: -1;
  transition: 0.2s ease-out;
}

.guessText:before {
  content: "";
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  right: -0.5rem;
  height: 5rem;
  z-index: -1;
}

.guessBlob {
  position: absolute;
  left: 0;
  /* top: 100%; */
  width: 1.5rem;
  height: 1.5rem;
  background: #f97031;
  background: var(--c-orange);
  border-radius: 50%;
  -webkit-transform: translateX(-50%) translateY(3px);
          transform: translateX(-50%) translateY(3px);
  box-shadow: 0 2px 9px 0 rgba(249,112,49, 0.58);
  box-shadow: 0 2px 9px 0 var(--c-transparent-orange);
  transition: 0.2s ease-out;
}

.guessBlob:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 50%;
  height: 50px;
  right: 0;
  background: #f97031;
  background: var(--c-orange);
  border-radius: 50%;
  z-index: -1;
  transition: 0.2s ease-out;
}

.hasGuessed .guess {
  -webkit-filter: none;
          filter: none;
  box-shadow: none;
}

input[type="range"]:not(:disabled):hover + .guess .guessText:after,
input[type="range"]:not(:disabled):hover + .guess .guessBlob,
input[type="range"]:not(:disabled):hover + .guess .guessBlob:after {
  background: #ffa780;
  background: var(--c-light-orange);
}

.hasGuessed .guessText:after {
  opacity: 0.5;
  background: #b0e3d2;
  background: var(--c-faded-green);
}

.hasGuessed .guessBlob {
  -webkit-transform: translateX(-50%) translateY(3px) scale(0.5);
          transform: translateX(-50%) translateY(3px) scale(0.5);
  background: #000;
  background: var(--c-dark);
  box-shadow: none;
}

.hasGuessed .guessBlob:after {
  opacity: 0;
}

input {
  width: 100%;
  margin: 0;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  height: 75px;
  margin-top: -50px;
  cursor: pointer;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus + .guess .guessText:before {
  outline: 4px auto #9c9c9c;
  outline: 4px auto var(--c-light-grey);
}

:global(.js-focus-visible :focus:not(.focus-visible))
  + .guess
  .guessText:before {
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 75px;
  width: 50px;
  cursor: pointer;
  opacity: 1;
  background: transparent;
  margin-top: -30px;
  border: 0;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}

input[type="range"]:disabled::-webkit-slider-runnable-track {
  background: rgba(0, 0, 0, 0);
}

input[type="range"]:disabled::-webkit-slider-thumb {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 75px;
  width: 50px;
  cursor: pointer;
  opacity: 1;
  background: transparent;
  margin-top: -30px;
  border: 0;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}

input[type="range"]:disabled::-moz-runnable-track {
  background: rgba(0, 0, 0, 0);
}

input[type="range"]:disabled::-moz-range-thumb {
  transform: scaleX(0);
}

input[type="range"]::-moz-focus-outer {
  border: 0;
}

input[type="range"]::-ms-tooltip {
  display: none;
}

input[type="range"]::-ms-tooltip {
  display: none;
}

input[type="range"]::-ms-track {
  width: 100%;
  border-width: 6px 6px;
  border-color: transparent;
  color: transparent;
  overflow: visible;
  background: transparent;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  background: transparent;
}

input[type="range"]::-ms-thumb {
  height: 75px;
  width: 50px;
  cursor: pointer;
  opacity: 1;
  background: transparent;
  border: 0;
}

.slideWrap {
  position: relative;
}

.fakeTrackWrap {
  position: absolute;
  top: 11px;
  height: 6px;
  left: 28px;
  right: 28px;
  background: transparent;
  border-radius: 3px;
  background: #b0e3d2;
  background: var(--c-faded-green);
  z-index: -1;
  overflow: hidden;
}

@media (min-width: 760px) {
  .fakeTrackWrap {
    top: 13px;
  }
}

.fakeTrack {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #346f6f;
  background: var(--c-bright-green);
  border-radius: 3px;
  transition: 0.1s ease-out;
}

@media not all and (min-resolution: 0.001dpcm) {
  .outputWrap,
  .fakeTrack {
    transition: 0s !important;
  }

  .hasGuessed .outputWrap,
  .hasGuessed .fakeTrack {
    transition: 1s 0.1s cubic-bezier(0.77, 0, 0.175, 1) !important;
  }
}

.hasGuessed .outputWrap,
.hasGuessed .fakeTrack {
  transition: 1s 0.1s cubic-bezier(0.77, 0, 0.175, 1);
}

.prompt {
  display: block;
  text-align: left;
  padding-left: 2rem;
  -webkit-transform: translateY(-80%);
          transform: translateY(-80%);
  color: #f97031;
  color: var(--c-orange);
  z-index: -1;
}

input[type="range"]:hover + .guess .prompt {
  color: #ffa780;
  color: var(--c-light-orange);
}

.hasGuessed .prompt {
  opacity: 0;
}

.countryDetail {
  position: relative;
  font-size: 12px;
  font-size: var(--fs-xs);
  margin-left: auto;
  max-width: 80px;
  position: absolute;
  bottom: 1rem;
  left: 35%;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-out;
  display: none;
}

@media (min-width: 760px) {
  .countryDetail {
    display: inline-block;
  }
}

.countryDetailVisible {
  opacity: 1;
}

@media (min-width: 500px) {
  .countryDetail {
    bottom: 1rem;
    left: 45%;
  }
}

@media (min-width: 760px) {
  .countryDetail {
    bottom: 2rem;
    left: 63%;
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
}

.countryDetail svg {
  overflow: visible;
  -webkit-transform: translateY(-20%) rotate(5deg);
          transform: translateY(-20%) rotate(5deg);
  margin-left: 1rem;
  position: absolute;
  left: 100%;
  top: 0%;
}
