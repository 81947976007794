.wrap {
}

.title {
  font-size: 16px;
  font-size: var(--fs-m);
}

.map {
  position: relative;
  margin-left: calc(1rem * -1);
  margin-left: calc(var(--s-2) * -1);
  margin-right: calc(1rem * -1);
  margin-right: calc(var(--s-2) * -1);
  margin-top: calc(0.5rem * -1);
  margin-top: calc(var(--s-1) * -1);
  margin-bottom: calc(1rem * -1);
  margin-bottom: calc(var(--s-2) * -1);
}
