@-webkit-keyframes switch {
  0%,
  2% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  98%,
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes switch {
  0%,
  2% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  98%,
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.wrap {
  text-align: center;
  background: #fff;
  background: var(--c-light);
  margin-left: calc(1.25rem * -1);
  margin-left: calc(var(--s-3) * -1);
  margin-right: calc(1.25rem * -1);
  margin-right: calc(var(--s-3) * -1);
  margin-top: calc(1.25rem * -1);
  margin-top: calc(var(--s-3) * -1);
  margin-bottom: 4rem;
  margin-bottom: var(--s-5);
  padding: 1.25rem 1.25rem 2.5rem;
  padding: var(--s-3) var(--s-3) var(--s-4);
  box-shadow: 0 0 23px 0 rgba(0,140,140, 0.21);
  box-shadow: 0 0 23px 0 var(--c-transparent-dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

@media (min-width: 768px) {
  .wrap {
    min-height: 100vh;
  }
}

.title {
  margin-bottom: 1.25rem;
  margin-bottom: var(--s-3);
}

.intro {
  margin-bottom: 1.25rem;
  margin-bottom: var(--s-3);
  font-size: 16px;
  font-size: var(--fs-m);
  max-width: 44rem;
  max-width: var(--w-m);
  margin-left: auto;
  margin-right: auto;
}

.image {
  width: 100%;
  max-width: 44rem;
  max-width: var(--w-m);
  margin-bottom: 1.25rem;
  margin-bottom: var(--s-3);
  border-bottom: 2px solid;
}

.image img {
  width: 100%;
}

.icon {
  margin-left: 0.5rem;
  margin-left: var(--s-1);
}

.dots {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}

.dot {
  position: absolute;
  top: 2rem;
  left: 2rem;
  right: 2rem;
  bottom: 2rem;
  -webkit-animation: switch 20s infinite alternate ease-in-out;
          animation: switch 20s infinite alternate ease-in-out;
}

.dot:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #b0e3d2;
  background: var(--c-faded-green);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
